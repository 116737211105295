.select-menu {
  position: relative;
  width: 100%;
}

.select-menu-placeholder {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  outline: 0;
  border: 1px solid gray;
  align-items: center;
}

.select-menu-shape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.select-menu-shape.active {
  pointer-events: auto;
}

.select-menu-main {
  border: 1px solid gray;
  border-radius: 5px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 0;
  width: 100%;
  top: 120%;
  transition: 200ms;
  z-index: 1000;
  background-color: #fff;
  padding: 5px;
}

.select-menu-options {
  max-height: 200px;
  overflow: hidden auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;

}

.select-menu-main.active {
  top: 100%;
  opacity: 1;
  pointer-events: auto;
}

.select-menu-main button {
  background-color: transparent;
  border: 0;
  outline: 0;
  text-align: start;
  padding: 3px 5px;
  transition: 200ms;
  background-color: #fff;
}

.select-menu-main button:hover {
  background-color: rgb(238, 238, 255);
}

.select-menu-search {
  outline: 0;
  padding: 2px 10px;
  margin-bottom: 5px;
  width: 100%;
}
