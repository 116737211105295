#kt_app_main {
  min-height: 89.2vh;
  justify-content: space-between;
}

.links-widget {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 999;
}

.links-widget .btn {
  font-size: 40px;
  background-color: #08003c !important;
  border-radius: 50%;
  padding: 15px 15px !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
}

.links-widget .btn:hover {
  transform: scale(1.03);
}

.links-widget .btn:active {
  transform: scale(.97) rotate(-180deg);
}

.links-widget .links {
  display: flex;
  flex-direction: column;
  background-color: #08003c;
  border-radius: 10px;
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 9999999999999;
  width: max-content;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 300ms;
  transition-timing-function: cubic-bezier(0, 1.25, 0.75, 1.36);
}

.links-widget .links.active {
  bottom: 70px;
  opacity: 1;
  pointer-events: auto;
}

.links-widget .links a {
  transition: 200ms;
  padding: 7px 20px;
}

.links-widget .links a:hover {
  background-color: #0a004b;
}

.links-widget-shape {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.links-widget-shape.active {
  pointer-events: auto;
}

.bg-light-warning {
  transition: 300ms !important
}

.editing {
  background-color: #fff !important;
  transform: scale(1.05);
  transition: 300ms ;
}

tr.active > td,
td.active {
  background-color: #f5f8fa;
}

.error-input {
  border-color: red !important;
}

.error-message {
  color: red;
}

.remove-btn {
  border: 0;
  background-color: transparent;
}

.add-btn {
  display: flex;
  margin-left: auto;
  padding: 10px 24px !important;
  font-size: 40px;
}

.remove-border-t {
  position: relative;
}

.remove-border-t::after {
  content: "";
  position: absolute;
  width: 100%;
  top: -2px;
  left: 0;
  height: 3px;
  background-color: #fff;
}


